<template>
<div class="video-controls d-flex justify-center align-center">


  <v-icon color="#fff"
          v-if="!paused"
          @click="$emit('pause')"
  >
    mdi-pause
  </v-icon>
  <v-icon color="#fff"
          v-if="paused"
          @click="$emit('play')"
  >
    mdi-play
  </v-icon>

  <v-slider
      class="progress ml-5"
      color="#fff"
      track-color="#888"
      hide-details
      :value="positionBuff"
      :max="duration"
      min="0"
      @start="seeking=true"
      @end="change"
  ></v-slider>

  <!--
  <v-progress-linear
      class="progress ml-5"
      v-model="progressPercent"
      color="#fff" rounded/>
      {{paused}}  {{position}} {{duration}}
  -->



</div>
</template>

<script>
export default {
name: "video-controls",
  props:["paused","duration","position"],
  mounted() {

  },
  data(){
    return{
      seeking:false
    }

  },
  computed:{
    positionBuff(){
      if(this.seeking){
        return null
      }else{
        return this.position
      }
    },
    progressPercent:{
      get () {
        return 100 / this.duration * this.position
      }
      /*
      ,
      set (val) {
        this.$emit("seek",val * ( this.duration /100) )
      }

       */
    }
  },
  methods: {
    change(value){
      this.seeking=false;
      this.$emit("seek",value)
    }
  },

}
</script>

<style lang="less">
  .video-controls{
    .progress{
      width: 300px;
    }
  }
</style>