/* globals $releaseUpdater */
export default class Layout{

    constructor() {
        /**
         *
         * @type {null|Place}
         */
        this._currentPlace=null;
        /**
         *
         * @type {null|Place}
         */
        this.lastPlace=null;
        /**
         *
         * @type {null|string}
         */
        this.videoMode=null;

        /**
         *
         * @type {Place[]}
         */
        this.places=[];

        this.isModeText=false;

        /**
         * Version du logiciel
         * @type {string}
         */
        this.version=require('../package.json').version;
        /**
         * Dit si le footer est visible ou non
         * @type {boolean}
         */
        this._footerVisible=true;
        let me=this;
        setTimeout(function(){
            me._footerVisible=false
        },30*1000);

    }

    /**
     *
     * @return {boolean|string}
     */
    get footerVisible(){
        return this._footerVisible || $releaseUpdater.nextVersion!=="";
    }

    getPlaceById(id){
        return this.places.find(place=>place.placeId===id);
    }

    set currentPlaceId(id){

        this._currentPlace=this.getPlaceById(id);
    }
    get currentPlace() {
        return this._currentPlace;
    }

    set currentPlace(value) {
        if(this._currentPlace){
            this.lastPlace=this._currentPlace;
        }
        this._currentPlace = value;
    }
}


