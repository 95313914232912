<template>
  <div class="video-player">
    <video :class="'big '+fermeture"
           :src="p.videoSrc"
           ref="video"
           autoplay muted />

    <v-btn x-large class="close ma-5" dark fab icon @click="fermer">
      <v-icon x-large>mdi-close</v-icon>
    </v-btn>

    <nav :class="'pa-5 text-right d-flex align-end '+fermeture">

      <div class="d-flex justify-center">
        <h1>{{p.titre}}</h1>
        <video-controls
            class="ml-5"
            :paused="paused"
                        :duration="duration"
                        :position="position"
                        @pause="m.pause()"
                        @play="m.play()"
                        @seek="seek"

        />
      </div>

      <v-spacer></v-spacer>

      <btns-texte-signe/>

      <!--
      <video class="small elevation-5"
             @click="$layout.isModeText=!$layout.isModeText"
             :src="p.videoSrcAlt"
             autoplay loop muted/>
             -->

    </nav>
  </div>

</template>

<script>
import VideoControls from "@/video-player/video-controls";
import BtnsTexteSigne from "@/btns-texte-signe";
export default {
name: "video-player",
  components: {BtnsTexteSigne, VideoControls},
  props:{
    place:{
      type:Object
    }
  },
  data(){
    return {
      duration:60,
      position:0,
      paused:false,
      fermeture:""
    }
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    let me =this;
    setTimeout(function(){

      me.$refs.video.addEventListener('ended',function(){
        me.fermer()
      },false);

      me.$refs.video.addEventListener('pause',function(){
        me.paused=true;
      },false);

      me.$refs.video.addEventListener('play',function(){
        me.paused=false;
      },false);

      me.$refs.video.addEventListener('timeupdate',function(){
        me.position=me.m.currentTime;
        me.duration=me.m.duration;
        console.log("update")
      },false);
    },1000)

  },
  computed:{
    /**
     *
     * @return {Place}
     */
    p(){
      return this.place;
    },
    /**
     *
     * @return {HTMLMediaElement}
     */
    m(){
      return this.$refs.video
    }
  },
  methods:{
    seek(seconds){
      this.position=seconds;
      this.m.currentTime=seconds;
      console.log(seconds)
    },
    fermer(){
      let me=this;
      me.fermeture="fermer";
      setTimeout(function(){
        me.$layout.currentPlace=null;
      },1000)

    }
  }
}
</script>

<style lang="less">
  .video-player{
    background-color: #000;
    overflow: hidden;
    width: var(--layout-width);
    height: var(--layout-height);
    position: relative;

    h1{
      color:#FFF;
      //text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      //max-width: 220px;
      text-align: left;
      background-color:#111;
      background-color:rgba(0,0,0,0.2);
      padding: 3px 6px;
      transform-origin: bottom left;
      //transform: skewY(-5deg);
      animation: show-texte 0.5s ;
      font-size: 16px;
    }

    .close{
      position: absolute;
      right: 0;
      top: 0;
    }
    video{
      object-fit: cover;
      background-color: #000;
      &.big{
        position: relative;
        width: 100%;
        height: 100%;
        animation: show-big 1.5s ;
        &.fermer{
          animation: hide-big 1.5s ;
        }
      }
    }

    nav{
      overflow: hidden;
      width: 100%;
      animation: from-bottom 1.0s ;
      position: absolute;
      bottom: 0;
      left: 0;

      &.fermer{
        animation: hide-big 0.5s ;
        animation-fill-mode: forwards;
      }
    }

    .video-controls{
      color: #fff;
    }

    @keyframes show-texte{
      from{
        opacity: 0;
        transform: translateY(20px);
      }
      to{
        opacity: 1;
        transform: translateY(0px);
      }
    }
    @keyframes from-bottom{
      from{
        transform: translateY(50px);
      }
      to{
        transform: translateY(0px);
      }
    }

    @keyframes show-small{
      from{
        opacity: 0;
        transform: translateY(20px) scale(0.5);
      }
      to{
        opacity: 1;
        transform: none;
      }
    }
    @keyframes show-big{
      from{
        opacity: 0;
        transform: scale(0.8);
        filter: saturate(0) brightness(0);
      }
      to{
        opacity: 1;
        transform: none;
        filter: none;
      }
    }
    @keyframes hide-big{
      to{
        opacity: 0;
        transform: scale(0.8);
        filter: saturate(0) brightness(0);
      }
      from{
        opacity: 1;
        transform: none;
        filter: none;
      }
    }



  }
</style>