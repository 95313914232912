<template>
<div class="plan-legende">
  <!--<h3>Légende</h3>-->
  <h3>
    Vous préférez la version <br>textes ou signes?
  </h3>
  <btns-texte-signe class="ml-n5"/>

  <div class="sens">
    <pieds class="pieds"/>
    <h5>Sens de la visite</h5>
  </div>

  <div class="actu">
    <pieds class="pieds"/>
    <h5>Position actuelle</h5>
  </div>

</div>
</template>

<script>
import BtnsTexteSigne from "@/btns-texte-signe";
import Pieds from "@/pieds";
export default {
name: "plan-legende",
  components: {Pieds, BtnsTexteSigne}
}
</script>

<style lang="less">
.plan-legende{
  display: flex;
  flex-direction: column;
  >*{
    //outline: 1px solid yellow;
    margin-bottom: 15px;
  }
  width: 300px;
  height: 300px;
  color: #888;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  user-select: none;
  h3{
    font-size: 14px;
    transform: skewY(-3deg) translateX(-10px);
    opacity: 0.5;
    margin-bottom: 20px;
    font-weight: 900;
  }
  .btns-texte-signe{
    >*{
      animation: none;
    }
    transform: skewY(-3deg);
    margin-bottom: 40px;
  }
  h5{
    transform: skewY(-3deg);
    font-size: 12px;
    font-weight: 500;

  }

  .sens,.actu{
    display: flex;
    align-items: center;
    >.pieds{
      width: 60px;
    }
    margin-left: 20px;
  }
  .sens{
    .pieds{
      transform: translateY(13px) translateX(20px) rotate(-20deg);
      filter: sepia(200%) saturate(2) brightness(0.3);
    }
  }
  .actu{
    .pieds {
      transform: translateY(20px) translateX(20px);
      filter: brightness(0.1) sepia(10) saturate(10) brightness(1);
    }

  }

  .pieds{

    position: relative;
    height: 50px;

    >*{
      animation: none;
      opacity: 1;
      visibility: visible;

    }

  }

}
</style>