<template>
  <v-app>
      <plan-expo v-if="!$layout.currentPlace"/>



      <video-player
          v-if="$layout.currentPlace"
          :place="$layout.currentPlace"
      />


    <v-footer v-if="$layout.footerVisible" absolute fixed dark color="transparent" class="text-right justify-end caption">
      {{$layout.version}}
      <span v-if="$releaseUpdater.nextVersion"> | Nouvelle version {{$releaseUpdater.nextVersion}} </span>
      <span v-if="$releaseUpdater.downloadProgress"> | {{$releaseUpdater.downloadProgress}}%</span>
      <v-btn x-small
             class="mx-5"
             @click="$releaseUpdater.updateAndRestart()"
             v-if="$releaseUpdater.available">
        {{$releaseUpdater.message}}
      </v-btn>
    </v-footer>
  </v-app>
</template>

<script>


import VideoPlayer from "@/video-player/video-player";
import PlanExpo from "@/plan-expo";
export default {
  name: 'App',
  components: {
    PlanExpo,
    VideoPlayer

  },
  data: () => ({
    transitionName:"fade",
    time:"0"
  }),
  watch: {
    '$route' (to, from) {
      this.transitionName = to.meta.ordre < from.meta.ordre ?  'slide-right' : 'slide-left';
      document.title=this.transitionName
    }
  }
};
</script>
<style lang="less">

  @import "assets/fonts/montserrat/montserrat.css";

  body,html{
    --layout-width: 1280px;
    --layout-height: 854px;
    overflow: hidden;
  }
  .v-application--wrap{
    background: #444;
    justify-content: center;
    align-items: center;
  }

  .page-container{
    outline: 1px solid #f4f4f4;
    outline-offset: -1px;
    margin-left: 15px;
    width: calc(100% - 30px);
    height: calc(100vh - 210px);
  }

</style>
