//const electron = require('electron');
//const remote = electron.remote;
//const isDevelopment = process.mainModule.filename.indexOf('app.asar') === -1;

import Vue from 'vue'

import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router'
import router from '@/router'
import Layout from "@/Layout";



/**
 *
 * @type {Layout}
 */
window.$layout=new Layout();
/**
 *
 * @type {Layout}
 */
Vue.prototype.$layout = Vue.observable(window.$layout);
require("./database");



/**
 *
 * @type {ReleaseUpdater}
 */
if(typeof __static !== "undefined"){
    require("./release-updater")
}else{
    window.$releaseUpdater= {};
}
/**
 *
 * @type {ReleaseUpdater}
 */
Vue.prototype.$releaseUpdater = Vue.observable(window.$releaseUpdater);

Vue.config.productionTip = false
Vue.use(VueRouter)
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');







