<template>
<div class="plan-expo">
  <div class="noise"></div>
  <div class="bg" ref="svg" v-html="svg"/>

  <div class="last-place"
       v-if="$layout.lastPlace"
       :style="'left:'+$layout.lastPlace.x+'px;top:'+$layout.lastPlace.y+'px;'"
  >

    <pieds class="player" v-for="item in 1"
           :key="'pietine'+item"
           :style="`offset-distance:${item*10}%;--delay:${item}s`"

    />

  </div>

  <div id="pieds">
    <pieds class="player" v-for="item in 100" :key="item"/>
  </div>

  <v-img max-width="250px"
         class="logo-crap"
         src="./assets/hd_logo.png"
  />

  <plan-legende/>

</div>
</template>

<script>
// eslint-disable-next-line
import raw from '!raw-loader!./assets/plan-app.svg';
import Pieds from "@/pieds";
import PlanLegende from "@/plan-legende";
export default {
  name: "plan-expo",
  components: {PlanLegende, Pieds},
  data(){
    return{
      svg:raw,
      pathO:null
    }
  },
  computed:{
    path(){
      return this.$refs['svg'];
    }
  },
  mounted() {
    let me=this;
    let $chemin=document.getElementById('chemin_1_');
    let $pieds=document.getElementById('pieds');

    $chemin.style.display="none";
    let t=0;
    let p=$chemin
        .getAttribute('d')
        .replace(/\s*/gm,'');
    $pieds.style.setProperty("--path",`path("${p}")`);
    for(let item of $pieds.getElementsByClassName("player")){
      t+=1;
      item.style.offsetDistance=String(t+"%");
      item.style.setProperty("--delay",String(t)+"s");
    }

    let $places=document.querySelectorAll("#places [id]");
    for(let $place of $places){

      $place.addEventListener("click", function(){
        me.$layout.currentPlaceId=$place.id;
      });

      let rect=$place.getBBox();
      let place=this.$layout.getPlaceById($place.id)
      place.x=rect.x;
      place.y=rect.y;
      //selectionne la dernière place visitée
      if(this.$layout.lastPlace){


        if(this.$layout.lastPlace.placeId === this.$layout.places[this.$layout.places.length-1].placeId){
          //la dernière place n'est jamais marquée comme current (c'est la fin de la visite)
          this.$layout.lastPlace=null;
        }else{
          if(place.placeId === this.$layout.lastPlace.placeId){
            $place.classList.add("last-one");
          }
        }

      }


    }


  }
}
</script>

<style lang="less">
  .plan-expo{
    &.animated{
      animation: show-big 1.5s ;
    }
    width: 1280px;
    height: 854px;
    overflow: hidden;
    background-color: #000;
    position: relative;
    >*{
      position: absolute;
      top: 0;
      left: 0;
    }
    .logo-crap{
      display: none;
      left: 600px;
      top: 600px;
      filter: invert(1);
    }
    .bg{
      z-index: 1;
    }

    .plan-legende{
      animation: show-texts 1.0s ease-in;
      bottom: 20px;
      top: auto;
      left: 700px;
      z-index: 2;
    }

    .noise{
      z-index: 2;
    }
    .pieds{
      z-index: 0;
    }
    .noise{
      mix-blend-mode: screen;
      background-image: url('./assets/noise.jpg');
      background-repeat: repeat;
      //mix-blend-mode: overlay;
      opacity: 0.3;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
    .bg{
      svg{
        position: absolute;
        left:0;
        top: 0;
        #fond{
          display: none;
        }
      }
    }
    @keyframes move {
      0% {
        offset-distance: 0%; /* je commence au début du trajet */
      }
      100% {
        offset-distance: 100%; /* je finis à la fin du trajet */
      }
    }

    #pieds{
      .pieds{
        pointer-events: none;
        left: 0;
        top: 0;
        offset-rotate: auto;
        //mix-blend-mode: screen;
        filter: sepia(200%) saturate(2) brightness(0.3);
        offset-path: var(--path);
        //offset-path: path("M617.9,1149.5c-106.5-24-177.2-7.9-222.9,12.4c-35,15.5-45.1,29-105.9,56.4c-77.1,34.8-115.6,52.1-159.6,39.9c-42.4-11.8-84.9-49.2-96.3-100.4c-12-53.9,12.8-112.5,53.7-140.3c66.1-45.1,140.9,18.1,196.7-30.3c24.3-21.1,38.2-57.1,33.7-88.1c-9.1-62.9-88.4-68.6-168.6-143C132.2,740.7,19.3,632.8,30.5,493.3c5.4-67,41-163,114.2-192.6c68.9-27.8,126.9,21.1,161-15.1c22.7-24.1,15.5-65.6,4.1-132.1c-8.3-48.2-13.6-76.4-38.5-92.2c-25.1-15.8-67.2-17.2-88,7.3c-22.4,26.5-9.1,70.2,11,93.1c36.6,41.8,108.2,28.5,125.1,25.4c93.8-17.4,112.2-86.9,168-79c55.2,7.7,52.2,77.9,110.1,93.6C679.7,223.9,742,96.8,816,119.1c57.1,17.2,41.8,99.3,125.2,148.6c50.6,29.9,103.6,26.7,133.5,24.8c144.8-9.5,182.8-123.1,305.4-114.2c8.6,0.6,66.4,5.6,115.6,48.2c63.2,54.7,109.5,169.4,53.7,247.6c-35.5,49.7-108.6,79.8-174.7,57.8c-68.1-22.7-92.3-88.6-94.9-96.3c-29.6-85.2,29.6-162.8,44-180.2c66.2-80.2,180.2-96,264.2-74.3c9.7,2.5,122.5,32.4,174.7,133.5c39.8,77-1.9,105.5,30.3,228.4c30,114.9,83.8,156.4,59.2,198.1c-20,33.9-76.9,42.6-118.3,34.4c-86.2-17.1-84-103-162.3-150c-118.5-71.1-213.5,71.7-419.6,22c-127-30.6-227-105.9-227-105.9c-64.5-48.6-79.1-81.2-137.6-96.3c-73-18.8-139.3,8.5-190.7,30.6c-51.4,22-94.1,40.3-103.7,80.9c-6.7,28.2,4.2,60.6,26.1,79.8c36.8,32.2,96.7,20.6,125.2-9.6c10.9-11.6,18.4-27.2,22-53.7c0.9-6.7,19.6-160-78.4-209.1c-87.2-43.7-189.1,34.1-229.2,77.3c-13.8,14.9-22.2,27.8-28.1,36.9c-45.2,70-67.5,166.2-49.5,218.8c30.6,89.7,213.7,154.9,433.4,85.3Z");
      }
    }


    .last-place{
      width: 100px;
      height: 100px;
      //outline: 1px solid red;
      color: white;
      .pieds{
        transform: translate(40px,70px) rotate(-30deg);
        filter: brightness(0.1) sepia(10) saturate(10) brightness(1)   ;
        >div{
          animation-duration: 5s;
          animation-name: showHidePietine;
        }

      }
    }


    svg{
      animation: show-texts 0.5s ease-in;
      tspan,text{
        font-family: "Montserrat", sans-serif !important;
        font-weight: 800;
        text-transform: uppercase;
      }
      #places{
        >*{
          cursor:pointer;
          @media(hover: hover){
            &:hover{
              text,tspan{
                fill: #b9b42b !important;
              }
            }
          }
          &.last-one{
            text,tspan{
              animation: lastOne 2s infinite;
              fill: #ece508 !important;
            }
          }

          @keyframes lastOne{
            from{
              fill: #E9E21F !important;
            }
            to{
              fill:#FFFFFF !important;
            }
          }
          animation: show-texts 3s ease-in;
        }


      }
    }
    @keyframes show-texts{
      from{
        opacity: 0;
      }
      to{
        opacity:1;
      }
    }

  }

</style>