<template>
  <div class="btns-texte-signe d-flex">
    <v-img  :max-width="iconsSize"
            :truc="$layout.isModeText?'0':'1'"
            @click="$layout.isModeText=false"
            class="ml-5 show-small"
            src="./assets/icone-signes.png"/>

    <v-img  :max-width="iconsSize"
            :truc="$layout.isModeText?'1':'0'"
            @click="$layout.isModeText=true"
            class="ml-5 show-small"
            src="./assets/icone-texte.png"/>
  </div>

</template>

<script>
export default {
  name: "btns-texte-signe",
  data(){
    return {
      iconsSize:"60px",
    }
  },
}
</script>

<style lang="less">
.btns-texte-signe{

  >[truc]{
    cursor: pointer;
    animation: transi-truc 2.0s ;
    transition: filter 0.5s;
  }
  >[truc='1']{
    filter:brightness(0.2);
  }

  @keyframes transi-truc{
    from{
      transform: translateY(50px);
    }
    to{
      transform: translateY(0px);
    }
  }

}



</style>