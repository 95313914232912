<template>
  <div class="pieds">
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
name: "pieds"
}
</script>

<style lang="less">
  .pieds{
    position: absolute;
    >div{
      opacity:0;
      visibility: hidden;
      backface-visibility: hidden;
      @s:10px;
      width: @s;
      height: @s*3;
      position: absolute;

      animation-name: showHide;
      animation-duration: 30s;
      animation-delay: var(--delay);
      animation-iteration-count: infinite;
      animation-timing-function: linear;

      background-size: contain;
      transform:rotate(90deg);
      &:first-child{
        top: -20px;
        background-image: url('./assets/pied-g.png');
      }
      &:last-child{
        animation-delay: calc( var(--delay) + 0.5s);
        background-image: url('./assets/pied-d.png');
      }
    }


    &:nth-child(odd){
      >div{
        &:first-child{
          transform: rotate(80deg);
        }
      }
    }
    &:nth-child(even){
      >div{
        &:last-child{
          transform:rotate(100deg);
        }
      }
    }

    @keyframes showHide{
      0%{
        opacity: 0;
        visibility: visible;
      }
      3%{
        opacity: 1;
      }
      20%{
        opacity: 0;
        visibility: hidden;
      }
      100%{
        opacity: 0;
      }
    }
    @keyframes showHidePietine{
      0%{
        visibility: visible;
        opacity: 0;
      }
      10%{
        opacity: 1;
      }
      90%{
        opacity: 1;
      }
      100%{
        opacity: 0;
      }
    }
  }

</style>