import Place from "@/Place";
//sources vidéos à dwd ici https://drive.google.com/drive/folders/1FpDtz-sYvGxqs-tsPAIAYwoy-jnp_VBZ
window.$layout.places.push(
    new Place(
        "enfance-a-sete",
        "L'enfance à Sète",
        "vdo/ZONE-1-texte.mp4",
        "vdo/ZONE-1-signes.mp4"
    )
);
window.$layout.places.push(
    new Place(
        "sete-a-paris",
        "De Sète à Paris",
        "vdo/ZONE-2-texte.mp4",
        "vdo/ZONE-2-signes.mp4"
    )
);
window.$layout.places.push(
    new Place(
        "les-cabarets",
        "Les cabarets",
        "vdo/ZONE-3-texte.mp4",
        "vdo/ZONE-3-signes.mp4"
    )
);
window.$layout.places.push(
    new Place(
        "inspiration-jazz",
        "Inspiration jazz",
        "vdo/ZONE-4-texte.mp4",
        "vdo/ZONE-4-signes.mp4"
    )
);
window.$layout.places.push(
    new Place(
        "le-plaisir-des-mots",
        "Le plaisir des mots",
        "vdo/ZONE-5-texte.mp4",
        "vdo/ZONE-5-signes.mp4"
    )
);
window.$layout.places.push(
    new Place(
        "poesie-et-musique",
        "Poésie et Musique",
        "vdo/ZONE-6-texte.mp4",
        "vdo/ZONE-6-signes.mp4"
    )
);
window.$layout.places.push(
    new Place(
        "eloge-de-la-femme",
        "Éloge de la femme",
        "vdo/ZONE-7-texte.mp4",
        "vdo/ZONE-7-signes.mp4"
    )
);
/*
window.$layout.places.push(
    new Place(
        "l-homme-de-scene",
        "L'homme de scène",
        "ZONE-8.mp4",
        "ZONE-8-texte.mp4"
    )
);
 */
window.$layout.places.push(
    new Place(
        "brassens-libertaire",
        "Brassens libertaire",
        "vdo/ZONE-9-texte.mp4",
        "vdo/ZONE-9-signes.mp4"
    )
);
window.$layout.places.push(
    new Place(
        "terminus-gare-de-sete",
        "Terminus Gare de Sète",
        "vdo/ZONE-10-texte.mp4",
        "vdo/ZONE-10-signes.mp4"
    )
);