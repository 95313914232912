/* globals $layout */
export default class Place{
    constructor(placeId,titre,videoText,videoSigns) {

        let root=".";
        if(typeof __static !== "undefined"){
            // eslint-disable-next-line no-unused-vars,no-undef
            root=__static;
        }

        /**
         * Identifiant
         * @type {string}
         */
        this.placeId=placeId;
        /**
         * @type {string}
         */
        this.titre=titre;
        /**
         * Url de la vidéo en mode texte
         * @type {string}
         */
        this.videoText=root+"/"+videoText;
        /**
         * Url de la vidéo en mode signes
         * @type {string}
         */
        this.videoSigns=root+"/"+videoSigns;

        this.x;
        this.y;
    }



    /**
     * Renvoie la vidéo en fonction du mode sélectionné
     * @return {string}
     */
    get videoSrc(){
        if($layout.isModeText){
            return this.videoText;
        }
        // eslint-disable-next-line no-undef
        return this.videoSigns;

    }
    /**
     * Renvoie la vidéo en fonction du mode NON sélectionné
     * @return {string}
     */
    get videoSrcAlt(){
        if(!$layout.isModeText){
            return this.videoText;
        }
        return this.videoSigns;

    }

    /**
     *
     * @return {HTMLElement}
     */
    get domObject(){
        return document.getElementById(this.placeId);
    }

    /**
     *
     * @return {DOMRect}
     */
    get rect(){
        if(this.domObject){
            return this.domObject.getBoundingClientRect()
        }
        return {}


    }
}